import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  connect() {
    super.connect()
  }

  // This allows the first level and second level of doubly nested forms to have
  // their own unique id.
  // See: https://github.com/stimulus-components/stimulus-rails-nested-form/pull/5#issuecomment-1101939902
  add(e) {
    e.preventDefault()
    const regex = new RegExp(`${this.data.get('new-record-placeholder')}`, 'g')
    const content = this.templateTarget.innerHTML.replace(regex, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }
}