import { Controller } from "stimulus"

export default class extends Controller {
  navigate(e) {
    e.preventDefault()

    let formData = new FormData(this.element.form)
    let queryString = new URLSearchParams(formData).toString()

    let link = document.createElement("a")
    link.href = this.data.get("url") + "?" + queryString
    link.dataset.turbo = "true"
    link.dataset.turbolinks = "false"

    this.element.parentElement.insertBefore(link, this.element)

    link.click()
  }
}