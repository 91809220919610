import { Controller } from "stimulus"
import {loadStripe} from '@stripe/stripe-js';

export default class extends Controller {
  static targets = ['checkout', 'confirmationTokenField', 'errorMessage']

  connect() {
    this.initCheckout()

    // Listen for the custom event from the event payment controller
    this.element.addEventListener('event-cost-updated', this.updateElementAmount.bind(this))
  }

  initCheckout() {
    loadStripe(this.data.get('publishable-key')).then(stripe => {
      this.stripe = stripe
      this.elements = stripe.elements({
        mode: "payment",
        amount: parseInt(this.data.get('amount')),
        currency: "usd",
      })

      this.elements.create('payment').mount(this.checkoutTarget)
   });
  }

  updateElementAmount(event) {
    if (parseInt(event.detail.amount) > 0) {
      this.elements.update({amount: parseInt(event.detail.amount)});
    }
  }

  async checkout(event) {
    if (this.striped) return

    this.submitBtn = event.target;

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // Prevent multiple form submissions
    if (this.submitBtn.disabled) {
      return;
    }

    // Disable form submission while loading
    this.submitBtn.disabled = true;

    // Trigger form validation and wallet collection
    const {error: submitError} = await this.elements.submit();
    if (submitError) {
      this.handleError(submitError);
      return;
    }

    // Create the ConfirmationToken using the details collected by the Payment Element
    // and additional shipping information
    const {error, confirmationToken} = await this.stripe.createConfirmationToken({
      elements: this.elements,
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      this.handleError(error);
      return;
    }

    // add a hidden field to the form with the confirmation token
    this.confirmationTokenFieldTarget.value = confirmationToken.id;

    this.striped = true

    this.submitBtn.form.requestSubmit()
  }

  handleError(error) {
    this.errorMessageTarget.textContent = error.message;
    this.errorMessageTarget.classList.remove('d-none')
    this.submitBtn.disabled = false;
  }
}