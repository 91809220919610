import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this._removeElement.bind(this))
  }

  _removeElement() {
    let el = document.querySelector(this.data.get("selector"))
    if (!el) { return }
    el.parentElement.removeChild(el)
  }
}
