import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "couponCode", "couponId", "couponRow", "couponAmount", "couponCodeDisplay",
    "error", "eventTotal", "submitButton", "stripeElement", "chargeAmount"
  ]

  connect() {
    this.eventCostCents = this.data.get("event-cost-cents")
    // For group registrations, the event cost is multiplied by the number of registrants
    this.eventCostMultiplier = this.data.get("event-cost-multiplier") || 1

    this.updateEventCost()
  }

  verify(e) {
    e.preventDefault()

    // Send the coupon code to the server to determine if it's valid.
    // If it is valid, display the coupon amount applied, and fill the couponId hidden field on the form
    let couponCode = this.couponCodeTarget.value
    let eventId = this.data.get("event-id")

    $.ajax({
      url: `/events/${eventId}/coupon_validator`,
      method: "POST",
      data: { coupon_code: couponCode },
      dataType: "json",
      success: (data) => {
        if (data !== null) {
          // set the coupon hidden field and clear error message
          this.couponIdTarget.value = data.id
          this.errorTarget.classList.add("d-none")

          // coupon details are displayed
          this.couponRowTarget.classList.remove("d-none")
          this.couponAmountTarget.innerText = `-$${((data.amount_cents / 100.0) * this.eventCostMultiplier).toFixed(2)}`
          this.couponCodeDisplayTarget.innerText = `"${data.code}"`

          // event cost is updated
          this.eventCostCents = data.event_discounted_cost_cents
          this.updateEventCost()
        } else {
          this.#displayError()
        }
      },
      error: (xhr, status, error) => {
        this.#displayError()
      }
    })
  }

  updateEventCost() {
    let costWithMultiplier = this.eventCostCents * this.eventCostMultiplier
    let costWithSymbol = `$${(costWithMultiplier / 100.0).toFixed(2)}`

    // cost details
    this.eventTotalTarget.innerText = costWithSymbol
    // submit button
    this.submitButtonTarget.value = this.data.get("submit-button-value").replace("{amount}", costWithSymbol)

    // stripe element is handled in the stripe controller
    const event = new CustomEvent('event-cost-updated', {
      detail: { amount: costWithMultiplier }
    });
    this.stripeElementTarget.dispatchEvent(event);

    // set a form field for the charge amount to validate on the server
    this.chargeAmountTarget.value = costWithMultiplier
  }

  #displayError() {
    // clear the coupon hidden field and display an error message
    this.couponIdTarget.value = ""
    this.errorTarget.classList.remove("d-none")

    // coupon details are hidden
    this.couponRowTarget.classList.add("d-none")

    //event cost is updated
    this.eventCostCents = this.data.get("event-cost-cents")
    this.updateEventCost()
  }
}